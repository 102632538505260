const actions = {
  ASYNC_UPDATE_TRASH_EXPAND_COLLAPSE: 'ASYNC_UPDATE_TRASH_EXPAND_COLLAPSE',

  GET_TRASH_BOARD_GROUPS_LIST: 'GET_TRASH_BOARDS_GROUPS_LIST',
  GET_TRASH_BOARD_GROUPS_LIST_SUCCESS: 'GET_TRASH_BOARD_GROUPS_LIST_SUCCESS',
  GET_TRASH_BOARD_GROUPS_LIST_FAILURE: 'GET_TRASH_BOARD_GROUPS_LIST_FAILURE',

  GET_TRASH_BOARDS_LIST: 'GET_TRASH_BOARDS_LIST',
  GET_TRASH_BOARDS_LIST_SUCCESS: 'GET_TRASH_BOARDS_LIST_SUCCESS',
  GET_TRASH_BOARDS_LIST_FAILURE: 'GET_TRASH_BOARDS_LIST_FAILURE',

  GET_TRASH_FOLDERS_LIST: 'GET_TRASH_FOLDERS_LIST',
  GET_TRASH_FOLDERS_LIST_SUCCESS: 'GET_TRASH_FOLDERS_LIST_SUCCESS',
  GET_TRASH_FOLDERS_LIST_FAILURE: 'GET_TRASH_FOLDERS_LIST_FAILURE',

  GET_TRASH_DOCUMENTS_LIST: 'GET_TRASH_DOCUMENTS_LIST',
  GET_TRASH_DOCUMENTS_LIST_SUCCESS: 'GET_TRASH_DOCUMENTS_LIST_SUCCESS',
  GET_TRASH_DOCUMENTS_LIST_FAILURE: 'GET_TRASH_DOCUMENTS_LIST_FAILURE',

  GET_TRASH_ITEMS_LIST: 'GET_TRASH_ITEMS_LIST',
  GET_TRASH_ITEMS_LIST_SUCCESS: 'GET_TRASH_ITEMS_LIST_SUCCESS',
  GET_TRASH_ITEMS_LIST_FAILURE: 'GET_TRASH_ITEMS_LIST_FAILURE',

  GET_TRASH_SPARKS_LIST: 'GET_TRASH_SPARKS_LIST',
  GET_TRASH_SPARKS_LIST_SUCCESS: 'GET_TRASH_SPARKS_LIST_SUCCESS',
  GET_TRASH_SPARKS_LIST_FAILURE: 'GET_TRASH_SPARKS_LIST_FAILURE',

  RESTORE_TRASH_BOARD_GROUPS: 'RESTORE_TRASH_BOARD_GROUPS',
  RESTORE_TRASH_BOARD_GROUPS_SUCCESS: 'RESTORE_TRASH_BOARD_GROUPS_SUCCESS',
  RESTORE_TRASH_BOARD_GROUPS_FAILURE: 'RESTORE_TRASH_BOARD_GROUPS_FAILURE',

  RESTORE_TRASH_BOARDS: 'RESTORE_TRASH_BOARDS',
  RESTORE_TRASH_BOARDS_SUCCESS: 'RESTORE_TRASH_BOARDS_SUCCESS',
  RESTORE_TRASH_BOARDS_FAILURE: 'RESTORE_TRASH_BOARDS_FAILURE',

  RESTORE_TRASH_FOLDERS: 'RESTORE_TRASH_FOLDERS',
  RESTORE_TRASH_FOLDERS_SUCCESS: 'RESTORE_TRASH_FOLDERS_SUCCESS',
  RESTORE_TRASH_FOLDERS_FAILURE: 'RESTORE_TRASH_FOLDERS_FAILURE',

  RESTORE_TRASH_DOCUMENTS: 'RESTORE_TRASH_DOCUMENTS',
  RESTORE_TRASH_DOCUMENTS_SUCCESS: 'RESTORE_TRASH_DOCUMENTS_SUCCESS',
  RESTORE_TRASH_DOCUMENTS_FAILURE: 'RESTORE_TRASH_DOCUMENTS_FAILURE',

  RESTORE_TRASH_ITEMS: 'RESTORE_TRASH_ITEMS',
  RESTORE_TRASH_ITEMS_SUCCESS: 'RESTORE_TRASH_ITEMS_SUCCESS',
  RESTORE_TRASH_ITEMS_FAILURE: 'RESTORE_TRASH_ITEMS_FAILURE',

  RESTORE_TRASH_SPARKS: 'RESTORE_TRASH_SPARKS',
  RESTORE_TRASH_SPARKS_SUCCESS: 'RESTORE_TRASH_SPARKS_SUCCESS',
  RESTORE_TRASH_SPARKS_FAILURE: 'RESTORE_TRASH_SPARKS_FAILURE',

  DELETE_TRASH_BOARD_GROUPS: 'DELETE_TRASH_BOARD_GROUPS',
  DELETE_TRASH_BOARD_GROUPS_SUCCESS: 'DELETE_TRASH_BOARD_GROUPS_SUCCESS',
  DELETE_TRASH_BOARD_GROUPS_FAILURE: 'DELETE_TRASH_BOARD_GROUPS_FAILURE',

  DELETE_TRASH_BOARDS: 'DELETE_TRASH_BOARDS',
  DELETE_TRASH_BOARDS_SUCCESS: 'DELETE_TRASH_BOARDS_SUCCESS',
  DELETE_TRASH_BOARDS_FAILURE: 'DELETE_TRASH_BOARDS_FAILURE',

  DELETE_TRASH_FOLDERS: 'DELETE_TRASH_FOLDERS',
  DELETE_TRASH_FOLDERS_SUCCESS: 'DELETE_TRASH_FOLDERS_SUCCESS',
  DELETE_TRASH_FOLDERS_FAILURE: 'DELETE_TRASH_FOLDERS_FAILURE',

  DELETE_TRASH_DOCUMENTS: 'DELETE_TRASH_DOCUMENTS',
  DELETE_TRASH_DOCUMENTS_SUCCESS: 'DELETE_TRASH_DOCUMENTS_SUCCESS',
  DELETE_TRASH_DOCUMENTS_FAILURE: 'DELETE_TRASH_DOCUMENTS_FAILURE',

  DELETE_TRASH_ITEMS: 'DELETE_TRASH_ITEMS',
  DELETE_TRASH_ITEMS_SUCCESS: 'DELETE_TRASH_ITEMS_SUCCESS',
  DELETE_TRASH_ITEMS_FAILURE: 'DELETE_TRASH_ITEMS_FAILURE',

  DELETE_TRASH_SPARKS: 'DELETE_TRASH_SPARKS',
  DELETE_TRASH_SPARKS_SUCCESS: 'DELETE_TRASH_SPARKS_SUCCESS',
  DELETE_TRASH_SPARKS_FAILURE: 'DELETE_TRASH_SPARKS_FAILURE',

  EMPTY_TRASH: 'EMPTY_TRASH',
  EMPTY_TRASH_SUCCESS: 'EMPTY_TRASH_SUCCESS',
  EMPTY_TRASH_FAILURE: 'EMPTY_TRASH_FAILURE',

  RESTORE_ALL_TRASH_ITEMS: 'RESTORE_ALL_TRASH_ITEMS',
  RESTORE_ALL_TRASH_ITEMS_SUCCESS: 'RESTORE_ALL_TRASH_ITEMS_SUCCESS',
  RESTORE_ALL_TRASH_ITEMS_FAILURE: 'RESTORE_ALL_TRASH_ITEMS_FAILURE',

  UPDATE_SELECTED_TRASH_ITEM: 'UPDATE_SELECTED_TRASH_ITEM',

  UPDATE_UNSELECTED_TRASH_ITEM: 'UPDATE_UNSELECTED_TRASH_ITEM',

  UPDATE_TRASH_SELECT_ALL: 'UPDATE_TRASH_SELECT_ALL',

  ORPHAN_OBJECTS: 'ORPHAN_OBJECTS',
  RESET_ORPHAN_OBJECTS: 'RESET_ORPHAN_OBJECTS',
  KEEP_IN_TRASH: 'KEEP_IN_TRASH',
  TRASH_GROUPS_SELECT_DROPDOWN_LIST: 'TRASH_GROUPS_SELECT_DROPDOWN_LIST',
  TRASH_COMMON_LOADER: 'TRASH_COMMON_LOADER',

  GET_TRASH_LIST: 'GET_TRASH_LIST',
  GET_TRASH_LIST_SUCCESS: 'GET_TRASH_LIST_SUCCESS',

  GET_TRASH_PAGINATION_LIST: 'GET_TRASH_PAGINATION_LIST',
  GET_TRASH_PAGINATION_LIST_SUCCESS: 'GET_TRASH_PAGINATION_LIST_SUCCESS',
  GET_TRASH_PAGINATION_LIST_FAILURE: 'GET_TRASH_PAGINATION_LIST_FAILURE',

  TRASH_CHECKBOX: 'TRASH_CHECKBOX',
  DELETE_MULTIPLE_ITEMS: 'DELETE_MULTIPLE_ITEMS',
  RESTORE_MULTIPLE_ITEMS: 'RESTORE_MULTIPLE_ITEMS',
  RESTORE_MULTIPLE_ITEMS_SUCCESS: 'RESTORE_MULTIPLE_ITEMS_SUCCESS',

  RESTORE_ORPHAN_OBJECTS: 'RESTORE_ORPHAN_OBJECTS',
  CREATE_FOLDER_MODAL_VISIBILITY: 'CREATE_FOLDER_MODAL_VISIBILITY',

  SET_TRASH_FOOTER_MODAL_VISIBILITY: 'SET_TRASH_FOOTER_MODAL_VISIBILITY',
  SET_TRASH_FOOTER_MODAL_LOADER: 'SET_TRASH_FOOTER_MODAL_LOADER',
};

export default actions;
