export const CANCEL_SUBSCRIPTION_MODAL = 'cancel_subscription_modal';
export const OFFER_MODAL = 'offer_modal';
export const SEVEN_DAYS_EXTENSION_MODAL = 'seven_days_extension_modal';
export const FREE_FOREVER_INDEX_MODAL = 'free_forever_index_modal';
export const FREE_FOREVER_WELCOME_MODAL = 'free_forever_welcome_modal';
export const FREE_FOREVER_THANKING_MODAL = 'free_forever_thanking_modal';
export const TWO_FOR_ONE_INDEX_MODAL = 'two_for_one_index_modal';
export const TWO_FOR_ONE_EMAIL_MODAL = 'two_for_one_email_modal';
export const DEVICE_SELECTION_MODAL = 'device_selection_modal';
export const FREE_FOREVER_PLAN_DETAILS_MODAL =
  'free_forever_plan_details_modal';

export const PLAN_STATUS = {
  IN_TRIAL: 'in_trial',
  TRIAL_END: 'trial_end',
  EXTENDED_TRIAL_END: 'extended_trial_end',
  IN_PREMIUM: 'in_premium',
  IN_FREE_FOREVER: 'in_free_forever',
};
export const PLAN_IDS = {
  PREMIUM_ANNUAL: 'pay_now_annual_subscription',
  PREMIUM_MONTHLY: 'premium_monthly',
  FREE_FOREVER: 'sp_free',
  TWO_FOR_ONE: '2_for_1_annual_subscription_discount',
  PREMIUM_ANNUAL_WITH_DISCOUNT: 'pay_now_annual_subscription_discount',
  PREMIUM_MONTHLY_WITH_DISCOUNT: 'pay_now_monthly_subscription_discount',
};
export const CURRENT_PLAN_STATUS = {
  ACTIVE: 'Active',
  CANCELLED: 'Cancelled',
  CHANGED: 'Changed',
};
